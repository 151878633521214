<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addTestCase">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesTest">
                <thead>
                  <tr>
                    <th>{{ testColumn.id }}</th>
                    <th>{{ testColumn.test_name }}</th>
                    <th>{{ testColumn.api_name }}</th>
                    <th>{{ testColumn.created_Date }}</th>
                    <th>{{ testColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in testData" :key="key">
                    <td style="width: 25%">{{ value.id }}</td>
                    <td style="width: 15%">{{ value.name }}</td>
                    <td style="width: 15%">{{ value.api }}</td>
                    <td style="width: 20%">{{ value.createdDate }}</td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateTestCase',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            :to="{
                              path: '/selectedTestCase',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >View</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ testColumn.id }}</th>
                    <th>{{ testColumn.test_name }}</th>
                    <th>{{ testColumn.api_name }}</th>
                    <th>{{ testColumn.created_Date }}</th>
                    <th>{{ testColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import MenuViewModal from "../components/common/MenuViewModal";
import ApiViewModel from "../components/common/ApiViewModel";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      testColumn: {
        id: "Id",
        test_name: "Test Name",
        api_name: "API Name",
        created_Date: "Created Date",
        action: "Action",
      },
      testData: {},
      dataTablesTest: "dataTables-test",
      exportTitle: "Test Case Details",
      data: {
        title: "Test Case",
        name: "",
        id: 0,
        url: Constant.url.TEST_CASE_URI_DELETE,
      },
      selectedTestData: {},
    };
  },
  components: {
    DeleteModal,
  },
  created: function () {
    this.fetchTestData();
    this.deleteEvent();
  },
  mounted: function () {
    DataTableService.loadDataTable(this.dataTablesTest, this.exportTitle);
  },
  methods: {
    fetchTestData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.TEST_CASE_URI_GET_ALL).then((result) => {
        self.testData = result;
      });
    },
    addModalData: function (value) {
      this.selectedTestData = value;
      this.data.name = value.name;
      this.data.id = value.id;
    },
    addMenuModaldata: function (value) {
      this.menuData = value;
    },
    addApiModaldata: function (value) {
      this.apiData = value;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.ROLE_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.roleTemplate(self.selectedTestData, "", [], [])
            )
          );
          self.fetchTestData();
          DataTableService.reLoadDataTable(self.dataTablesTest);
          DataTableService.loadDataTable(self.dataTablesTest, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.view {
  color: #1c84c6f7;
}
</style>
