var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper wrapper-content animated fadeInRight"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"ibox"},[_c('div',{staticClass:"ibox-title"},[_c('h5'),_c('div',{staticClass:"ibox-tools"},[_c('router-link',{attrs:{"to":"addTestCase"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("    Add ")])],1)]),_c('div',{staticClass:"ibox-content"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-hover",class:_vm.dataTablesTest},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.testColumn.id))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.test_name))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.api_name))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.created_Date))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.action))])])]),_c('tbody',_vm._l((_vm.testData),function(value,key){return _c('tr',{key:key,staticClass:"gradeX"},[_c('td',{staticStyle:{"width":"25%"}},[_vm._v(_vm._s(value.id))]),_c('td',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(value.name))]),_c('td',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(value.api))]),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(value.createdDate))]),_c('td',{staticStyle:{"width":"2%"}},[_c('i',{staticClass:"fas fa-user-cog"}),_vm._m(0,true),_c('ul',{staticClass:"dropdown-menu dropdown-user"},[_c('li',[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                            path: '/updateTestCase',
                            query: { id: value.id },
                          }}},[_vm._v("Edit")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                            path: '/selectedTestCase',
                            query: { id: value.id },
                          }}},[_vm._v("View")])],1),_c('li',[_c('a',{staticClass:"dropdown-item",attrs:{"data-toggle":"modal","data-target":"#myModal4"},on:{"click":function($event){return _vm.addModalData(value)}}},[_vm._v("Delete")])])])])])}),0),_c('tfoot',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.testColumn.id))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.test_name))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.api_name))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.created_Date))]),_c('th',[_vm._v(_vm._s(_vm.testColumn.action))])])])])]),_c('DeleteModal',{attrs:{"data":_vm.data}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('i',{staticClass:"fa fa-wrench"})])
}]

export { render, staticRenderFns }